<template>
  <v-app-bar app color="primary" title="info about AppLayoutLinks">
    v-app-bar (in LINKS)
    <v-btn to="/"> Home </v-btn> 
    <v-btn to="/about">About</v-btn> |
    <v-btn to="/contacts">Contacts</v-btn> |
    <v-btn to="/help">Help</v-btn>
    <v-btn to="/other">Other</v-btn>
    <v-btn to="/grid">Grid</v-btn>
    <v-btn to="/transitions">Transitions</v-btn>
    <v-btn to="/svg">SvgTest</v-btn>
    <v-btn to="/carousel">Carousel Test</v-btn>
    <v-spacer />
    <v-btn icon><v-icon>mdi-login</v-icon></v-btn>
    <v-btn icon><v-icon>mdi-logout</v-icon></v-btn>
    <v-btn icon><v-icon>mdi-theme-light-dark</v-icon></v-btn>
    <v-btn icon><v-icon>mdi-account-settings</v-icon></v-btn>
  </v-app-bar>
</template>

<script>
export default {
name: "AppLayoutLinks"
}
</script>
